.list {

}

.spinner {
    text-align: center;
    padding: 100px 0;
}

@media (min-width: 992px) {
    .map {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        align-self: flex-start;
    }
}
