.container {
    padding-top: 1.5rem;
}

.header {
    font-size: 0.85rem;
    line-height: 60px;
}

.stats {
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 20px;
}