.container {
    text-decoration: none;
    color: inherit;
    display: block;
}

.container:hover {
    color: inherit;
}

.image {
    width: 100%;
    padding-top: 66%;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    background-color: var(--bs-gray-200);
}

.title {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: -2px;
    margin-top: 5px;
}

.dates {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    color: #6c6c6c;
}

.location {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    color: #6c6c6c;
}

.animalIcon {
    font-size: 18px;
    color: #63686d;
    margin-right: 6px;
    vertical-align: text-bottom;
}

.footer {
    display: flex;
    justify-content: space-between;
    height: 25px;
}

.logo {

}